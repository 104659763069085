<template>
  <gf-base>
    <gf-card v-loading="loading" subtitle="Create new Stock Adjustment request" title="New Stock Adjustment">
      <template #toolbar>
        <gf-button class="mr-2" type="primary" @click="save">Create Request</gf-button>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <el-form ref="sa" :model="sa" :rules="saRules" size="medium">
        <gf-content overflow>
          <template #title>
            <el-form-item label="Reason" label-width="75px" prop="reason">
              <el-input v-model="sa.reason" style="width: 250px"></el-input>
            </el-form-item>
          </template>
          <template #toolbar>
            <p class="font-weight-bold text-muted">#{{ sa.sa_number }}</p>
          </template>
          <div>
            <gf-table :data="sa.items" :scrollable="false">
              <el-table-column label="Item Name" min-width="200">
                <template slot-scope="slot">
                  <el-form-item v-if="selectedItems[slot.$index] === undefined" :prop="'items.' + slot.$index + '.variant_id'" :rules="{ required: true, message: ' ', trigger: 'change' }" label-width="0">
                    <el-select v-model="slot.row.variant_id" :remote-method="searchVariant" filterable placeholder="Start typing SKU or Name" popper-class="gf-select-product" remote size="mini" style="width: 100%" @change="changeSelectedItem($event, slot.$index)">
                      <el-option v-for="variant in variants" :key="variant.id" :label="variant.name" :value="variant.id">
                        <div v-if="variant.product.name === variant.name && variant.is_pack === 0" class="gf-select-product__name">{{ variant.name }}</div>
                        <div v-else-if="variant.is_pack === 0" class="gf-select-product__name">{{ variant.product.name }}  - {{ variant.name }}</div>
                        <div v-else class="gf-select-product__name">
                          <span v-if="variant.product.name === variant.variant.name">{{ variant.variant.name }} - {{ variant.name }}</span>
                          <span v-else>{{ variant.product.name }} - {{ variant.variant.name }} - {{ variant.name }}</span>
                          <span v-if="variant.is_pack === 1"> (Pack of {{ variant.pack_size }})</span>
                        </div>
                        <div class="gf-select-product__sub">
                          <span class="gf-select-product__sub__sku">SKU: {{ variant.sku }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div v-else class="gf-item">
                    <div v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].name && selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].name }}</div>
                    <div v-else-if="selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].name }}</div>
                    <div v-else class="gf-item__name">
                      <span v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].variant.name">{{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                      <span v-else>{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                      <span>(Pack of {{ selectedItems[slot.$index].pack_size }})</span>
                    </div>
                    <div class="gf-item__sub">
                      <span class="gf-item__sub__sku">SKU: {{ selectedItems[slot.$index].sku }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Qty Change" min-width="120">
                <template slot-scope="slot">
                  <el-form-item :prop="'items.' + slot.$index + '.qty_change'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                    <el-input v-model="slot.row.qty_change" size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Cost per Item" min-width="120">
                <template slot-scope="slot">
                  <el-form-item :prop="'items.' + slot.$index + '.cost'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                    <el-input v-model="slot.row.cost" size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Available" min-width="120">
                <template slot-scope="slot">
                  <el-input :value="$NumberFormat(parseFloat(slot.row.stock))" readonly size="mini" style="width: 100%"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="After" min-width="120">
                <template slot-scope="slot">
                  <el-form-item label-width="0">
                    <el-input :value="$NumberFormat(parseFloat(slot.row.qty_change) + parseFloat(slot.row.stock))" readonly size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" label="" width="80">
                <template slot-scope="slot">
                  <el-button v-model="slot.row.delete" icon="el-icon-delete" size="mini" type="danger" @click="deleteItem(slot.$index)"></el-button>
                </template>
              </el-table-column>
            </gf-table>
          </div>
          <div class="add-item">
            <gf-button light size="sm" style="width: 100%" type="primary" @click="addItem">+ Add another item</gf-button>
          </div>
        </gf-content>
      </el-form>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}

.el-form {
  height: 100%;
}
</style>

<script>
// services
import VariantService from '@/services/v1/Variant'
import StockAdjustmentService from '@/services/v1/StockAdjustment'

export default {
  data () {
    return {
      // ui
      loading: false,

      // main data
      sa: {
        sa_number: 'SA000000',
        warehouse_id: 1,
        reason: null,
        message: null,
        items: []
      },
      selectedItems: [],

      // rules
      saRules: {
        sa_number: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },

      // variables
      variants: [],

      // filters
      variantFilter: {}
    }
  },
  methods: {
    // handlers
    addItem () {
      this.sa.items.push({ variant_id: null, qty_change: 0, cost: 0, stock: 0 })
    },
    deleteItem (index) {
      this.sa.items.splice(index, 1)
      this.selectedItems.splice(index, 1)
    },
    back () {
      this.$router.back()
    },
    save () {
      this.$refs.sa.validate()
        .then(async () => {
          try {
            this.loading = true
            if (this.sa.items.length === 0) {
              this.$notify.error({
                title: 'Error',
                message: 'Stock Adjustment must contain at least 1 item'
              })
              return
            }

            const sa = this.$_.cloneDeep(this.sa)
            this.$Sanitize(sa)

            sa.total_cost = 0
            sa.quantity = 0

            for (let i = 0; i < sa.items.length; i++) {
              const item = sa.items[i]
              delete item.stock
              sa.total_cost += parseFloat(item.qty_change) * parseFloat(item.cost)
              sa.quantity += parseFloat(item.qty_change)
            }

            const saService = new StockAdjustmentService()
            const result = await saService.create(sa)
            this.$message.success(`${this.sa.sa_number} successfully submitted`)
            this.$router.push({ name: 'stock-adjustment-detail', params: { id: result.data.id } })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // selection
    changeSelectedItem (id, index) {
      const exist = this.sa.items.filter(item => item.variant_id === id).length

      if (exist > 1) {
        this.$notify.error({
          title: 'Duplicate Entry',
          message: 'Item already exist!'
        })
        this.sa.items[index].variant_id = null
        return
      }

      const item = this.variants.find(vr => vr.id === id)
      this.selectedItems[index] = item

      const price = item.prices.find(pl => pl.price_list_id === 1)
      if (!price) this.sa.items[index].cost = 0
      else this.sa.items[index].cost = parseFloat(price.value)

      const ss = item.stocks[0]

      if (item.is_pack) {
        this.sa.items[index].stock = parseFloat(ss ? ss.value : 0) / parseFloat(item.pack_size)
      } else {
        this.sa.items[index].stock = ss ? ss.value : 0
      }
    },

    // Select Search Method
    async searchVariant (query) {
      try {
        if (query && query !== '') {
          this.variantFilter.q = {
            like: query
          }
        } else delete this.variantFilter.q

        await this.getVariants()
      } catch (error) {
        this.$error500()
      }
    },

    // Service fetch
    async getVariants () {
      try {
        const vrService = new VariantService()
        const result = await vrService.list(null, null, this.variantFilter)
        this.variants = result.data.rows
      } catch (error) {
        this.$Error(500)
      }
    },
    async setSANumber () {
      try {
        const saService = new StockAdjustmentService()
        const result = await saService.getLastSA()
        let saNumber = result.data.sa_number

        if (!saNumber) saNumber = 'SA000000'

        const number = parseInt(saNumber.substring(2)) + 1
        this.sa.sa_number = 'SA' + this.$Numeral(number).format('000000')
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    }
  },
  async mounted () {
    this.loading = true
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock Control', route: '' },
      { title: 'Stock Adjustment' },
      { title: 'New' }
    ])

    await this.setSANumber()
    await this.getVariants()
    this.loading = false
  }
}
</script>
