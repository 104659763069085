import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['stock-control', 'stock-adjustments']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  getLastSA () {
    return this.get(null, '/last-sa')
  }

  getHistory () {
    return this.get(null, '/history')
  }

  approve () {
    return this.post(null, '/approve')
  }

  reject () {
    return this.post(null, '/reject')
  }

  cancel () {
    return this.post(null, '/cancel')
  }

  void () {
    return this.post(null, '/void')
  }
}
